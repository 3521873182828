<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0" >
        <span @click="$router.go(-1)" >
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Detalles categoría
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editCategory">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group
                  :label="'Nombre'"
                  label-for="project-name"
                >
                  <b-form-input
                    v-model="category.name"
                    name="name"
                    :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  :name="'Orden'"
                  rules="required"
              >
                <b-form-group
                    :label="'Orden'"
                    label-for="category-order"
                >
                  <b-form-input
                      v-model="category.order"
                      name="order"
                      type="number"
                      min="0"
                      step="1"
                      :placeholder="'Orden'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  :name="'Departamento'"
                  rules="required"
              >
                <b-form-group
                    :label="'Departamento'"
                    label-for="category-department"
                >
                  <v-select
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="selectDepartments"
                    :placeholder="'Departamento'"
                    v-model="category.department"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
              >
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import { config } from '@/shared/app.config'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
    config,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      projectPrepare: false,
    }
  },
  computed: {
    ...mapGetters({
      category: 'categories/getCategory',
      selectDepartments: 'departments/getSelectDepartments',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'categories/edit',
      getCategory: 'categories/getCategory',
      getSelectDepartments: 'departments/selectDepartments',
    }),
    handleSubmit() {
      this.$refs.editCategory.validate().then(success => {
        if (success) {
          const { id } = this.category
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, category: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      if (this.category.department) {
        data.append('department_id', this.category.department.id)
      } else {
        data.append('department_id', '')
      }

      data.append('name', this.category.name)
      data.append('order', this.category.order)

      return data
    },
  },
  async created() {
    await this.getCategory(this.$route.params.id)
    await this.getSelectDepartments({})
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
